import * as React from 'react';
import { Grid, Row, Col } from '@perfocal/UIKit';
import { SegmentResource } from '../resources/segment.resource';
import { Seo } from '../components/Seo';

export default class ThankYou extends React.Component {
  segmentResource: SegmentResource;

  constructor() {
    super({});

    this.segmentResource = new SegmentResource();
  }

  componentDidMount() {
    this.segmentResource.segmentLoad();

    document.title = 'Thank You | Perfocal';
  }

  render() {
    return (
      <div>
        <div className="nav-container">
          <nav className="bar bar-1 bar--sm bar--mobile-sticky bar--absolute">
            <Grid style={{ position: 'relative' }}>
              <Row>
                <Col md={12}>
                  <a target="_blank" href={`https://www.${process.env.DOMAIN}`}>
                    <img
                      alt="logo"
                      className="logo logo-dark"
                      src="https://dkk5oy4zs0vc4.cloudfront.net/logo-dark.svg"
                    />
                    <img
                      alt="logo"
                      className="logo logo-light"
                      src="https://dkk5oy4zs0vc4.cloudfront.net/logo-light.svg"
                    />
                  </a>
                  <div className="logo-motto hidden-xs">
                    Everyone's perfect photographer.
                  </div>
                </Col>
              </Row>
            </Grid>
          </nav>
        </div>
        <Seo title="Thank You" />
        <section className="height-100 bg--secondary text-center">
          <Grid className="pos-vertical-center">
            <Row>
              <Col xs={12} sm={8} smOffset={2}>
                <div className="thank-you">
                  <i
                    className="icon icon-Yes type--bold icon--lg color--primary-1"
                    style={{ border: 'none' }}
                  ></i>
                  <h1 className="h1 color--primary type--bold">
                    Your booking was successful.
                  </h1>
                  <h3 className=" type--bold">
                    Check your email inbox for a booking confirmation.
                  </h3>
                  <h4 className="color--primary type--bold">
                    Here's what's next:
                  </h4>
                </div>
                <Row className="mb--1">
                  <div className="process-2">
                    <Col sm={4}>
                      <div className="process__item ">
                        <h4>1. Get matched</h4>
                        <p>
                          Your photographer is in the mail. We're confirming the
                          best local photographer for you
                        </p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="process__item">
                        <h4>2. Get chatting</h4>
                        <p>
                          Say Hi! We've found the best photos are a result of
                          good communication.
                        </p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="process__item">
                        <h4>3. Get ready</h4>
                        <p>
                          Prepare for your shoot and meet your photographer.
                        </p>
                      </div>
                    </Col>
                  </div>
                </Row>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}
